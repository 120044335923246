import { usePathname } from 'next/navigation';
import { createContext, useContext, useMemo, useState } from 'react';

import useDeskpassAPI from '@/api/deskpass/useAPI';

import useWatch from '@/hooks/useWatch';

import {
  extractSlugsFromPathname,
  isInRoomDetailPage,
  isInSpaceDetailPage,
} from '@/lib/booking-resource-paths';
import { consumerToHOC } from '@/lib/hoc';

const Context = createContext({});

/*
 * Hold current space or room while a detail page is opened
 */
export function CurrentRoomOrSpaceProvider({ children, serverData }) {
  const currentRoomOrSpaceFromServer = serverData.currentRoomOrSpace;

  const pathname = usePathname();

  const [isRedirect, setIsRedirect] = useState(false);

  const inSpaceDetailPage = useMemo(
    () => isInSpaceDetailPage(pathname, true),
    [pathname],
  );

  const inRoomDetailPage = useMemo(
    () => isInRoomDetailPage(pathname, true),
    [pathname],
  );

  const [spaceSlug, roomSlug] = useMemo(
    () => extractSlugsFromPathname(pathname, true),
    [pathname],
  );

  const [
    {
      ready: spaceReady,
      loading: spaceLoading,
      data: currentSpace,
      error: currentSpaceError,
    },
    fetchCurrentSpace,
    { updateState: updateCurrentSpace },
  ] = useDeskpassAPI(
    (api) =>
      async (...args) => {
        const space = await api.space.getOne(
          ...(args.length ? args : [spaceSlug]),
        );

        setIsRedirect(space && space.slug !== spaceSlug);

        return space;
      },
    {
      fireOnMount: !currentRoomOrSpaceFromServer && inSpaceDetailPage,
      ...(currentRoomOrSpaceFromServer &&
        inSpaceDetailPage && {
          initialState: {
            ready: true,
            data: currentRoomOrSpaceFromServer,
          },
        }),
    },
  );

  const [
    {
      ready: roomReady,
      loading: roomLoading,
      data: currentRoom,
      error: currentRoomError,
    },
    fetchCurrentRoom,
    { updateState: updateCurrentRoom },
  ] = useDeskpassAPI(
    (api) =>
      async (...args) => {
        const room = await api.room.getOne(
          ...(args.length ? args : [roomSlug, spaceSlug]),
        );

        setIsRedirect(room && room.slug !== roomSlug);

        return room;
      },
    {
      debug: true,
      fireOnMount: !currentRoomOrSpaceFromServer && inRoomDetailPage,
      ...(currentRoomOrSpaceFromServer &&
        inRoomDetailPage && {
          initialState: {
            ready: true,
            data: currentRoomOrSpaceFromServer,
          },
        }),
    },
  );

  const ready = spaceReady || roomReady;
  const loading = spaceLoading || roomLoading;
  const inDetailPage = inSpaceDetailPage || inRoomDetailPage;

  /*
   * Reset state every time the pathname changes
   */
  useWatch(() => {
    if (inDetailPage) return;

    const emptyState = {
      error: undefined,
      data: undefined,
      ready: false,
      loading: false,
    };

    updateCurrentSpace({ ...emptyState });
    updateCurrentRoom({ ...emptyState });
  }, [inDetailPage]);

  /*
   * Fetches space when navigating to a
   * space detail page but not on initial load.
   */
  useWatch(
    (prevInSpaceDetail, prevSpaceSlug) => {
      const gotInSpaceDetail = !prevInSpaceDetail && inSpaceDetailPage;
      const slugChanged = inSpaceDetailPage && prevSpaceSlug !== spaceSlug;

      if (gotInSpaceDetail || slugChanged) {
        fetchCurrentSpace();
      }
    },
    [inSpaceDetailPage, spaceSlug],
    false,
  );

  /*
   * Fetches room when navigating to a
   * room detail page but not on initial load.
   */
  useWatch(
    (prevInRoomDetailPage, prevRoomSlug) => {
      const gotInRoomDetail = !prevInRoomDetailPage && inRoomDetailPage;
      const slugChanged = inRoomDetailPage && prevRoomSlug !== roomSlug;

      if (gotInRoomDetail || slugChanged) {
        fetchCurrentRoom();
      }
    },
    [inRoomDetailPage, roomSlug, spaceSlug],
    false,
  );

  const context = useMemo(
    () => ({
      isRedirect,
      inDetailPage,
      inSpaceDetailPage,
      inRoomDetailPage,
      ready,
      spaceReady,
      roomReady,
      loading,
      spaceLoading,
      roomLoading,
      currentSpace,
      currentSpaceError,
      fetchCurrentSpace,
      updateCurrentSpace,
      currentRoom,
      currentRoomError,
      fetchCurrentRoom,
      updateCurrentRoom,
      oldOrSubmittedSpaceSlug: spaceSlug,
      oldOrSubmittedRoomSlug: roomSlug,
    }),
    [
      isRedirect,
      inDetailPage,
      inSpaceDetailPage,
      inRoomDetailPage,
      ready,
      spaceReady,
      roomReady,
      loading,
      spaceLoading,
      roomLoading,
      currentSpace,
      currentSpaceError,
      fetchCurrentSpace,
      updateCurrentSpace,
      currentRoom,
      currentRoomError,
      fetchCurrentRoom,
      updateCurrentRoom,
      spaceSlug,
      roomSlug,
    ],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export const withCurrentRoomOrSpaceContext = consumerToHOC(
  Context.Consumer,
  'currentRoomOrSpaceContext',
);
export const useCurrentRoomOrSpaceContext = () => useContext(Context);

export default Context;
