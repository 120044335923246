import { createContext, useContext, useMemo, useState } from 'react';

import { useNotificationContext } from '@/context/Notification';

import useEvent from '@/hooks/useEvent';
import { useMountLayout } from '@/hooks/useMount';

import { consumerToHOC } from '@/lib/hoc';
import { pathMatchRouteName } from '@/lib/routes';

const Context = createContext({});

function watchUserLocation(onSuccess, onError) {
  const matchCurrentPath = (it) =>
    pathMatchRouteName(window.location.pathname, it);

  // Routes where we don't want to load user location
  const notInRoutes = ['guestInvitationIndex'].some(matchCurrentPath);

  // Abort if not applicable
  if (notInRoutes || !window.navigator || !window.navigator.geolocation) {
    return;
  }

  // Watch for updates to user position
  window.navigator.geolocation.watchPosition(onSuccess, onError, {
    timeout: 8000,
    maximumAge: 1000 * 60 * 60 * 24, // Cache geolocation position for a full day
  });
}

/*
 * Watches for user geolocation changes
 */
const Provider = ({ children }) => {
  const { create: createNotification } = useNotificationContext();

  // Set to true the first time the success or error callback is fired
  const [ready, setReady] = useState(false);

  // User denied to share geolocation
  const [userDeclined, setUserDeclined] = useState(false);

  // Geolocation available
  const [available, setAvailable] = useState(false);

  // Keep the last error around to see if it changed
  const [lastError, setLastError] = useState(null);

  // Geolocation coordinates, lat and lng
  const [coords, setCoords] = useState({});

  useMountLayout(() => {
    function onSuccess(position) {
      setCoords({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });

      setAvailable(true);

      if (!ready) setReady(true);
    }

    function onError(err) {
      if (!lastError || err?.code !== lastError.code) {
        if (err) setLastError(err);
        if (!ready) setReady(true);

        // User denied
        if (err.code === 1) setUserDeclined(true);

        // Position unavailable
        if (err.code === 2) {
          createNotification(
            'Your location is currently unavailable.',
            'warning',
          );
        }

        // Timeout
        if (err.code === 3) {
          createNotification(
            'We’re having trouble getting your location.',
            'warning',
          );
        }

        console.error('Error geolocating user: ', err.code, err.message);
      }
    }

    watchUserLocation(onSuccess, onError);
  });

  const retryWatchingUserLocation = useEvent(() => {
    if (available || userDeclined) return;

    watchUserLocation();
  });

  const context = useMemo(
    () => ({
      ready,
      userDeclined,
      available,
      lastError,
      coords,
      retryWatchingUserLocation,
    }),
    [
      ready,
      userDeclined,
      available,
      lastError,
      coords,
      retryWatchingUserLocation,
    ],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useGeolocationContext = () => useContext(Context);
export const GeolocationProvider = Provider;
export const withGeolocationContext = consumerToHOC(
  Context.Consumer,
  'geolocationContext',
);
export default Context;
