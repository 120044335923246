import { Loader, LoaderStatus } from '@googlemaps/js-api-loader';
import { createContext, useContext, useMemo, useState } from 'react';

import LoadingContent from '@/components/helpers/LoadingContent';

import { useMountLayoutAsync } from '@/hooks/useMount';

import config from '@/lib/config';

const Context = createContext({});

const isServer = typeof window === 'undefined';

const loader = new Loader({
  apiKey: config.GOOGLE_MAPS_API_KEY,
  /*
   * NOTE
   *
   * We were using "weekly" here so it keeps getting updates.
   * It worked for a long time but on version 3.60 it broke
   * all of our markers.
   *
   * I looked into the docs and there was no breaking change
   * in the APIs we use so it's likely a bug on the version.
   *
   * From now on we should use a fixed version to avoid these
   * kind of problems and we should update it manually when
   * we are ready to test it.
   */
  version: '3.59',
});

// Load the Google Maps SDK on the browser
const googleMapPromise = isServer ? Promise.resolve() : loader.load();

// export this in case we need to do something outside react
export async function getGoogleMapsSdkInstance() {
  return await googleMapPromise;
}

function isLoaderStatus(status) {
  return loader.status === LoaderStatus[status];
}

export function GoogleMapProvider({ children }) {
  const [googleMapsSdk, setGoogleMapSdk] = useState(() => null);

  useMountLayoutAsync(async () => {
    // TODO handle error for when it happens map won't load
    const google = await getGoogleMapsSdkInstance();

    const { Map } = await google.maps.importLibrary('maps');
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

    setGoogleMapSdk({
      ready: true,
      google,
      // Add other specific pieces of the SDK here as needed
      Map,
      AdvancedMarkerElement,
    });
  });

  const context = useMemo(
    () => ({
      // Imported pieces of the SDK
      ...googleMapsSdk,
      // Access loader status and other properties
      loader,

      get loading() {
        return isLoaderStatus('LOADING');
      },

      get hasError() {
        return isLoaderStatus('FAILURE');
      },
    }),
    [googleMapsSdk],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export const useGoogleMapContext = () => useContext(Context);

/*
 * Can use to make sure that the component is only rendered when the map is ready
 */
export function onGoogleMapReady(Component) {
  return function GoogleMapReadyComponent(props) {
    const { ready } = useGoogleMapContext();

    if (!ready) {
      return <LoadingContent />;
    }

    const displayName = Component.displayName || Component.name;
    Component.displayName = `GoogleMapReady(${displayName})`;

    return <Component {...props} />;
  };
}

export default Context;
